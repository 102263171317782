import React, { createContext, useCallback, useEffect, useState, useContext } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { config } from 'app/redux/config';

export type AppConfig = {
  title: string;
  description: string;
  github_link?: string;
  video_fit?: "cover" | "contain";
  settings: UserSettings;
  show_qr?: boolean;
};

export type UserSettings = {
  editable: boolean;
  theme_color: string;
  chat: boolean;
  inputs: {
    camera: boolean;
    mic: boolean;
    sip?: boolean;
  };
  outputs: {
    audio: boolean;
    video: boolean;
  };
  ws_url: string;
  token: string;
};

// Use the config from the imported Redux configuration
const defaultConfig: AppConfig = config.livekit.appConfig;

const SETTINGS_STORAGE_KEY = '@ConfigProvider:settings';

type ConfigData = {
  config: AppConfig;
  setUserSettings: (settings: UserSettings) => void;
};

const ConfigContext = createContext<ConfigData | undefined>(undefined);

export const ConfigProvider = ({ children }: { children: React.ReactNode }) => {
  const [appConfig, setAppConfig] = useState<AppConfig>(defaultConfig);
  const [localColorOverride, setLocalColorOverride] = useState<string | null>(null);

  // Load settings from AsyncStorage on initial mount
  useEffect(() => {
    const loadSettings = async () => {
      try {
        const savedSettingsJson = await AsyncStorage.getItem(SETTINGS_STORAGE_KEY);
        if (savedSettingsJson) {
          const savedSettings = JSON.parse(savedSettingsJson) as UserSettings;
          setAppConfig(prev => ({
            ...prev,
            settings: savedSettings
          }));
        }
      } catch (error) {
        console.error('Failed to load settings from AsyncStorage:', error);
      }
    };

    loadSettings();
  }, []);

  // Save settings to AsyncStorage whenever they change
  const saveSettings = async (settings: UserSettings) => {
    try {
      await AsyncStorage.setItem(SETTINGS_STORAGE_KEY, JSON.stringify(settings));
    } catch (error) {
      console.error('Failed to save settings to AsyncStorage:', error);
    }
  };

  const setUserSettings = useCallback(
    (settings: UserSettings) => {
      if (appConfig.settings.editable === false) {
        setLocalColorOverride(settings.theme_color);
        return;
      }
      
      // Save settings to AsyncStorage
      saveSettings(settings);
      
      // Update state
      setAppConfig((prev) => ({
        ...prev,
        settings: settings,
      }));
    },
    [appConfig.settings.editable]
  );

  useEffect(() => {
    if (localColorOverride && appConfig.settings.editable === false) {
      setAppConfig(prev => ({
        ...prev,
        settings: {
          ...prev.settings,
          theme_color: localColorOverride
        }
      }));
    }
  }, [localColorOverride, appConfig.settings.editable]);

  return (
    <ConfigContext.Provider value={{ config: appConfig, setUserSettings }}>
      {children}
    </ConfigContext.Provider>
  );
};

export const useConfig = () => {
  const context = useContext(ConfigContext);
  if (context === undefined) {
    throw new Error("useConfig must be used within a ConfigProvider");
  }
  return context;
};