import React, { useState } from 'react'
import {
  View,
  Text,
  TouchableOpacity,
  Image,
  StyleSheet,
} from 'react-native'
import { useUpload } from './upload-context'
import { UploadFile } from 'app/components/upload-file'
import { makeUseAxiosHook } from 'app/redux/store'
import { useToast } from 'app/redux/hooks/use-toast'
import Spin from 'app/components/spin'


const UploadOptionsStep: React.FC = () => {
  const message = useToast()
  const { closeModal, setStep, setImage,updateFormField } = useUpload()

  const [selectedImage, setSelectedImage] = useState<any>(null)
  const [isGeneratingCaption, setIsGeneratingCaption] = useState(false)

  const [{ loading }, getCaption] = makeUseAxiosHook('default', {
    manual: true,
    autoCancel:false
  })({
    url: `/api/v1/caption-from-image`,
    method: 'POST',
  })
  
  const handleImageUpload = async (files: any) => {
    if (!files.length) return
    const file = files[0]
    if (file) {
      // Set the selected image for preview
      setSelectedImage(file)
      setIsGeneratingCaption(true)
      
      const feedFormData = new FormData()
      feedFormData.append('file', file?.file)
      
      getCaption({
        data: feedFormData
      }).then((res:any) => {
        console.log(res.data.imageInfo);
        const [item]=res.data.imageInfo;
        setImage(item.image_url)
        updateFormField('caption', item.caption)
        updateFormField('uploadId', item.uploadId)
        setIsGeneratingCaption(false)
        setStep(2)
      }).catch((error) => {
        setIsGeneratingCaption(false)
        message({
          message: error.response?.data?.message,
          type: 'error',
        })
      })
    }
  }

  const renderContent = () => {
    if (selectedImage && isGeneratingCaption) {
      return (
        <View style={styles.previewContainer}>
          <Image 
            source={{ uri: selectedImage.url || URL.createObjectURL(selectedImage.file) }}
            style={{
              width: '100%',
              height: 300,
              borderRadius: 8,
              marginBottom: 16,
              minWidth:300
            }}
            resizeMode="contain"
            alt='feed'
          />
        </View>
      )
    }
    
    return (
      <UploadFile
        multiple={false}
        styles={{
          shadowColor: 'transparent', shadowOpacity: 0, elevation: 0
        }}
        onChange={(files) => {
          handleImageUpload(files)
        }}
      />
    )
  }

  return (
    <Spin spinning={loading} searchText='Generating Caption...' >
      <View style={styles.container}>
        <Text style={styles.title}>Create new post</Text>
        
        {renderContent()}
        
        <View style={styles.buttonContainer}>
          <TouchableOpacity style={styles.cancelButton} onPress={closeModal}>
            <Text style={styles.cancelButtonText}>Cancel</Text>
          </TouchableOpacity>

          <TouchableOpacity
            style={styles.nextButton}
            disabled={!selectedImage || isGeneratingCaption}
            onPress={() => {
              if (selectedImage && !isGeneratingCaption) {
                setStep(2)
              }
            }}
          >
            <Text 
              style={[
                styles.nextButtonText, 
                (!selectedImage || isGeneratingCaption) ? styles.disabledText : {}
              ]}
            >
              Next
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </Spin>
  )
}

const styles = StyleSheet.create({
  container: {
    padding: 20,
    backgroundColor: '#FFF9F5',
  },
  title: {
    fontSize: 18,
    fontWeight: '600',
    textAlign: 'center',
    marginVertical: 16,
  },
  previewContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: 20,
  },

  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 20,
    borderTopWidth: 1,
    borderTopColor: '#e0e0e0',
    paddingTop: 16,
  },
  cancelButton: {
    paddingVertical: 10,
    paddingHorizontal: 16,
  },
  cancelButtonText: {
    fontSize: 16,
    color: '#333',
  },
  nextButton: {
    paddingVertical: 10,
    paddingHorizontal: 16,
  },
  nextButtonText: {
    fontSize: 16,
    color: '#3897f0',
    fontWeight: '600',
  },
  disabledText: {
    opacity: 0.5,
  },
})

export default UploadOptionsStep