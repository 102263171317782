import { Button } from 'app/components/button';
import { Icon } from 'app/components/icon/icon';
import { TextField } from 'app/components/text-field';
import { useSx } from 'dripsy';
import React, { useState, useRef, useEffect } from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  Animated,
  TextInput,
  StyleSheet,
  ViewStyle,
  TextStyle,
  Platform,
  Keyboard,
} from 'react-native';

// Define types for props
interface TagInputProps {
  // Core functionality
  tags: string[];
  onTagsChange: (newTags: string[]) => void;
  
  // Optional props
  placeholder?: string;
  containerStyle?: ViewStyle;
  inputStyle?: TextStyle;
  tagStyle?: ViewStyle;
  tagTextStyle?: TextStyle;
}

const TagInput: React.FC<TagInputProps> = ({
  tags = [],
  onTagsChange,
  placeholder = 'Add Tag',
  containerStyle,
  inputStyle,
  tagStyle,
  tagTextStyle,
}) => {
  const [tagInput, setTagInput] = useState<string>('');
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [keyboardVisible, setKeyboardVisible] = useState<boolean>(false);
  
  // Animation values
  const borderAnimation = useRef(new Animated.Value(0)).current;
  const inputRef = useRef<TextInput>(null);

  // Track keyboard visibility
  useEffect(() => {
    const keyboardDidShowListener = Keyboard.addListener(
      'keyboardDidShow',
      () => {
        setKeyboardVisible(true);
      }
    );
    const keyboardDidHideListener = Keyboard.addListener(
      'keyboardDidHide',
      () => {
        setKeyboardVisible(false);
      }
    );

    return () => {
      keyboardDidHideListener.remove();
      keyboardDidShowListener.remove();
    };
  }, []);

  const handleAddTag = () => {
    if (tagInput.trim() !== '' && !tags.includes(tagInput.trim())) {
      // Store current keyboard state
      const wasKeyboardVisible = keyboardVisible;
      
      // Update tags and clear input
      onTagsChange([...tags, tagInput.trim()]);
      setTagInput('');
      
      // On Android, prevent keyboard from dismissing
      if (Platform.OS === 'android') {
        // Immediate focus might get lost due to state updates
        // Use a slightly longer timeout on Android
        setTimeout(() => {
          if (inputRef.current) {
            inputRef.current.focus();
            
            // If keyboard was visible but is now hidden, force it to show
            if (wasKeyboardVisible && !keyboardVisible) {
              Keyboard.dismiss();
              setTimeout(() => {
                inputRef.current?.focus();
              }, 100);
            }
          }
        }, 50);
      } else {
        // For iOS and web, a shorter timeout is usually sufficient
        setTimeout(() => {
          inputRef.current?.focus();
        }, 10);
      }
    }
  };

  const handleRemoveTag = (indexToRemove: number) => {
    const newTags = [...tags];
    newTags.splice(indexToRemove, 1);
    onTagsChange(newTags);
  };

  const handleInputFocus = () => {
    setIsFocused(true);
    Animated.timing(borderAnimation, {
      toValue: 1,
      duration: 200,
      useNativeDriver: false,
    }).start();
  };

  const handleInputBlur = () => {
    if (tagInput.trim() === '') {
      setIsFocused(false);
      Animated.timing(borderAnimation, {
        toValue: 0,
        duration: 200,
        useNativeDriver: false,
      }).start();
    }
  };

  // Animation interpolations
  const animatedWidth = borderAnimation.interpolate({
    inputRange: [0, 1],
    outputRange: ['0%', '100%'],
  });

  const sx = useSx();

  return (
    <View style={[styles.container, containerStyle]}>
      <View style={styles.inputWrapper}>
        {/* Bookmark icon */}
        <View style={styles.iconContainer}>
          <Icon name='bookmark-outline' />
        </View>
        
        <View style={styles.inputContainer}>
          <TextField
            ref={inputRef}
            value={tagInput}
            onChangeText={setTagInput}
            placeholder={placeholder}
            onSubmitEditing={handleAddTag}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            textFieldContainerStyle={{
                borderColor:'transparent',
            }}
            style={[{
                width:'90%',
            }, inputStyle]}
            blurOnSubmit={false} // This is crucial for Android
          />
          
          {/* Animated border that goes from left to right */}
          <Animated.View
            style={[
              styles.animatedBorder,
              { width: animatedWidth },
            ]}
          />
          
          {/* Add button only shows when input is focused */}
          {isFocused && (
            <Button
              style={{
                height:30,
                width:30
              }}
              onPress={handleAddTag}
              variantType='outlined'
            >
             <Icon name='add' style={{ width:18,height:18 }} />
            </Button>
          )}
        </View>
      </View>

      {/* Tags container */}
      {tags.length > 0 && (
        <View style={styles.tagsContainer}>
          {tags.map((tag, index) => (
            <View
              key={index}
              style={[{
                flexDirection: 'row',
                alignItems: 'center',
                backgroundColor: '#f5f5f5',
                borderRadius: 16,
                paddingHorizontal: 4,
                paddingVertical: 2,
              }, tagStyle]}
            >
              <Text style={[{
                 color: '#333',
                 fontSize: 14,
              }, tagTextStyle]}>{tag}</Text>
              <TouchableOpacity
                style={styles.tagRemoveButton}
                onPress={() => handleRemoveTag(index)}
              >
                 <Icon name="cancel" style={{ height:22,width:22, marginBottom:-8}}/>
              </TouchableOpacity>
            </View>
          ))}
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    // width: '100%',
  },
  inputWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 12,
  },
  iconContainer: {
    marginRight: 8,
  },
  inputContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative',
    paddingBottom: 4,
  },
  input: {
    flex: 1,
    paddingVertical: 8,
    fontSize: 16,
    borderWidth: 0,
  },
  animatedBorder: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    height: 2,
    backgroundColor: '#cd7f32',
  },
  tagsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: 12,
    gap: 8,
  },
  tagText: {
    color: '#333',
    fontSize: 14,
  },
  tagRemoveButton: {
    marginLeft: 6,
  },
});

export default TagInput;