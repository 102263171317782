import { useSx } from 'dripsy'
import React, { ReactNode, useEffect, useState } from 'react'
import {
  View,
  ActivityIndicator,
  StyleSheet,
  Text,
  StyleProp,
  ViewStyle,
} from 'react-native'

interface SpinProps {
  indicator?: React.ReactNode
  size?: 'small'| 'large'
  spinning?: boolean
  tip?: React.ReactNode
  fullscreen?: boolean
  color?: string
  style?: StyleProp<ViewStyle>
  children?: ReactNode;
  searchText?:string
}

const Spin: React.FC<SpinProps> = ({
  spinning = true,
  size='small',
  children,
  style,
  searchText=''
}) => {
  const sx=useSx()
  return (
    <>
      {spinning? (
        <View
          style={[{
            height: '100%',
            width: '100%',
            position: 'absolute',
            backgroundColor: '#f8fcff80',
            zIndex: 999999,
            display:'flex',
            justifyContent:'center',
            alignItems:'center'
          },style]}
        >
          <ActivityIndicator
            size={size}
            style={{
              // position: 'absolute',
              zIndex: 9999,
              // left: '48%',
              // top: '45%',
            }}
          />
         {searchText? <Text style={sx({
              fontSize: 16,
              fontWeight: '500',
              color: '#000',
              marginTop: 8,
         })} > {searchText} </Text>: <></>}
        </View>
      ) : (
        <></>
      )}
      {children && <>{children}</>}
    </>
  )
}


export default Spin
