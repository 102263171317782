import { Text, useSx, View } from 'dripsy';
import React, { useState, useEffect, createRef } from 'react';
import { H5 } from '../typography/h5';
import { Camera, CameraType } from 'expo-camera';
// import Ionicons from '@expo/vector-icons/Ionicons';
import { Button } from '../button';
import { TextStyle } from 'react-native';
import { Icon } from '../icon/icon';
import { I18Text } from 'app/provider/i18-provider';

interface CameraViewProps {
  styles?: TextStyle
  onChange: (e: any) => void
  onCancel: () => void
  showCamera: boolean
  mainModalView?: { height: number, width: number },
  setImages: (e: any) => void
}

export function CameraView({
  styles = {},
  onChange,
  onCancel,
  showCamera,
}: CameraViewProps) {
  const cameraRef = createRef<any>();
  const sx = useSx();
  const [image, setImage] = useState({});
  const [hasPermission, setHasPermission] = useState<boolean>(false);
  const [type] = useState(CameraType.back);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const { status } = await Camera.requestCameraPermissionsAsync();
      setHasPermission(status === 'granted');
      setLoading(false);
    })();
  }, []);
 
  return (
      <View style={sx({ flex: 1, height: '100%', width: '100%', backgroundColor: 'transparent' })}>
        {false ? <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
          <Text style={sx({ color: '$primary' })}>Loading...</Text>
        </View> : hasPermission && !loading ? (
          <View
            style={[
              sx({
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                height:200
              }),
            ]}
          >
            <Camera
              ref={cameraRef}
              style={[
                sx({
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: 200,
                  minWidth: '100%',
                  marginBottom:5
                })
              ]}
              type={type}
            ></Camera>
            <View style={{ flexDirection: 'row' }}>
              <Button
                onPress={async () => {                    
                  if (Object.keys(image).length === 0) {
                    if (cameraRef.current) {
                      const photo = await cameraRef.current.takePictureAsync({ base64: true });
                      if (photo) {
                        await cameraRef.current.pausePreview();
                      }
                      setImage(photo);
                    }
                  } else {               
                    onChange(image);
                    onCancel();
                    setImage({});
                  }
                }
                }
                style={{
                  minWidth: 100,
                  height:25
                }}
              >
                <I18Text onlyString >
                {Object.keys(image).length === 0 ? 'Capture' : 'Done'}
                </I18Text>
              </Button>
              <Button
                onPress={async() => { 
                  await cameraRef.current.resumePreview();
                //  await cameraRef.current.stopRecording()
                  setImage({}); 
                  onCancel(); 
                }}
                style={{
                  minWidth: 100,
                  height:25
                }}
              >
                <I18Text>
                {'Cancel'}
                </I18Text>
              </Button>
            </View>
          </View>
        ) : (
          <View
            style={[
              sx({
                display: 'flex',
                alignItems: 'center',
                height: '100%',
                marginTop: 2,
              }),
            ]}
          >
            <Icon name="checkmark" />
            <I18Text
              style={[
                sx({
                  color: '$primary',
                  fontSize: 18,
                  marginBottom: 2,
                }),
                styles,
              ]}
            >
              Please allow access to your camera
            </I18Text>
            <H5
              style={[
                sx({
                  color: 'red',
                }),
                styles,
              ]}
            >
              <I18Text onlyString >
              In order to take pictures or record video with your camera, please
              allow camera access for this site.
              </I18Text>
            </H5>
          </View>
        )}
      </View>
  );
}
