import { Text, useSx, View, Pressable, Image, ScrollView, P } from 'dripsy'
import React, { useState, useEffect, useRef } from 'react'
import * as DocumentPicker from 'expo-document-picker'
import * as ImagePicker from 'expo-image-picker';
import { H5 } from '../typography/h5'
import { Platform, ViewStyle } from 'react-native'
import { Icon } from '../icon/icon'
import { dataURLtoFile } from 'app/util/helper'
import { CameraView } from './camera';
import { I18Text } from 'app/provider/i18-provider';


interface UploadFileProps {
  styles?: ViewStyle
  onChange?: (e: any) => void
  uploadType?: string
  btnTitle?: string
  uploadNote?: string
  value?: any[]
  multiple?: boolean
  showCameraIcon?: boolean
  videoType?: boolean
  takePhoto?: boolean
  pickFromGallery?: boolean;
  isShowHelperMessage?:boolean;
  imagePreview?:boolean;
  helperMessage?:string;
  uploadIconStyles?: ViewStyle;
  allowedFileTypes?:string
}

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })

export function UploadFile({
  value,
  styles = {},
  multiple = true,
  onChange,
  showCameraIcon = true,
  takePhoto = false,
  pickFromGallery = false,
  isShowHelperMessage=true,
  imagePreview=false,
  helperMessage = "Ensure optimal results by uploading high-quality images.",
  uploadIconStyles,
  allowedFileTypes='image/*'
}: UploadFileProps) {
  const sx = useSx()
  const [images, setImages] = useState<any[]>([])
  const [showCamera, setShowCamera] = useState(false)
  const [dragging, setDragging] = useState(false)
  const drop = useRef<any>(null)

  // for mobile
  const pickImage = async () => {
    // No permissions request is necessary for launching the image library
    await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: false,
      allowsMultipleSelection:false,
      // aspect: [4, 3],
      base64:true,
      quality: 1,
    }).then(async (response: any) => {
      if (!response.canceled) {
        const filesToBeUploaded: any[] = []
          if (!response.assets.length) return
          const files = response.assets
            files.map(async (item) => {
              filesToBeUploaded.push({
                name: item.fileName || item.name || `${Date.now()}.jpeg` ,
                size: item.fileSize,
                type: item.mimeType || 'image/jpeg',
                url: `data:image/jpeg;base64,${item.base64}`,
                file: {
                  uri: item.uri,
                  name: item.fileName || item.name || `${Date.now()}.jpeg` ,
                  type: item.mimeType || 'image/jpeg'
                },
              })
            })
          if (multiple) {
            setImages([...images, ...filesToBeUploaded])
          } else {
            setImages([...filesToBeUploaded])
          }
      }
    }).catch((error)=>{
      console.log('error',error);
    })
  }

  const webPickImage = async () => {
    // No permissions request is necessary for launching the image library
    await DocumentPicker.getDocumentAsync({
      type: allowedFileTypes,
      multiple: multiple,
      // copyToCacheDirectory: true,
    }).then(async (response: any) => {
        const filesToBeUploaded: any[] = [];
          if (response.output) {
            for (let i = 0; i < response.output.length; i++) {
              filesToBeUploaded.push({
                name: response.output.item(i)?.name,
                size: response.output.item(i)?.size,
                type: response.output.item(i)?.type,
                url: await toBase64(response.output.item(i)),
                file: response.output.item(i),
              });
            }
            if (multiple) {
              setImages([...images, ...filesToBeUploaded]);
            } else {
              setImages([...filesToBeUploaded]);
            }
          }
    });
  };


  useEffect(() => {
    if (takePhoto) {
      setShowCamera(true)
    }
  }, [takePhoto])

  useEffect(() => {
    if (pickFromGallery) {
      if(Platform.OS !=='web'){
        pickImage()
      }else{
        webPickImage()
      }
    }
  }, [pickFromGallery])

  useEffect(() => {
    onChange && onChange(images)
  }, [images])

  const [mainModalView, setMainModalView] = useState<any>({
    height: 0,
    width: 0,
  })

  useEffect(() => {
    if (value) {
      setImages(value)
    } else {
      setImages([])
    }
  }, [value])

  const handleDragOver = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    setDragging(true)
  }

  const [hasPermission, setHasPermission] = useState<boolean>(false);
  const [status, requestPermission] = ImagePicker.useCameraPermissions();
  const errorMessage = "Camera access denied. Please enable camera permissions manually in your device settings.";

  useEffect(() => {
    (async () => {
      if (hasPermission || status?.status === ImagePicker.PermissionStatus.GRANTED) {
        // If already have permission or status is null, do nothing
        setHasPermission(true)
        return;
      }
  
      if (status?.canAskAgain) {
        const permissionResponse = await requestPermission();
        setHasPermission(permissionResponse.status === ImagePicker.PermissionStatus.GRANTED);
      } else {
        // If cannot ask again, set hasPermission to false
        setHasPermission(false);
      }
    })();
  }, [hasPermission, status]);

  const handlePress = async () => {
    if (Platform.OS === 'web') {
      setShowCamera(!showCamera);
    } else {
      if (hasPermission) {
        ImagePicker.launchCameraAsync({ base64: true  }).then((response) => {
         
          const filesToBeUploaded:any= [];
          const files = response?.assets|| []
        
          files.forEach((item) => {
            if (!item) return;
            const size = typeof item.fileSize === 'number' ? item.fileSize : 0; // Ensure size is always defined
            filesToBeUploaded.push({
              name: item?.fileName || `${Date.now()}.jpeg`,
              size,
              type: item.mimeType || 'image/jpeg',
              url: `data:image/jpeg;base64,${item.base64}`,
              file: {
                uri: item.uri,
                name: item.fileName || `${Date.now()}.jpeg`,
                type: item.mimeType || 'image/jpeg'
              },
            });
          });
          setImages([
           ...filesToBeUploaded
          ])
        });
      } else {
        
      }
    }
  };

  return (
    <>
    <View
      style={[
        sx({
          shadowColor: '#000',
          shadowOffset: { width: 1, height: 1 },
          shadowOpacity: 0.2,
          shadowRadius: 5,
          elevation: 3,
          backgroundColor: `${dragging ? '$lightGrey' : '#FFF9F5'}`,
          borderColor: '$primary',
          borderRadius: 7,
          padding: 15,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: [229, 300],
          cursor: 'pointer',
          position: 'relative',
        }),
        styles,
        Platform.select({
          native: sx({
            maxWidth: '100%',
          }),
        }),
      ]}
      ref={drop}
    >
      {showCamera ? (
        <View
          style={[
            sx({
              display: 'flex',
              justifyContent: 'space-around',
              flexDirection: 'row',
              alignItems: 'center',
              width: '100%',
              maxWidth: 350,
            }),
          ]}
        >
          <CameraView
            showCamera={showCamera}
            mainModalView={mainModalView}
            setImages={setImages}
            onChange={async (img) => {
              let file: any = null
              const fileName = `${Date.now()}.png`
              if (Platform.OS === 'web') {
                file = await dataURLtoFile(img.uri, fileName)
              } else {
                file = await dataURLtoFile(
                  `data:image/png;base64,${img.base64}`,
                  fileName
                )
              }
              setImages([
                {
                  name: fileName,
                  url: img.uri,
                  type: 'image/png',
                  file,
                },
              ])
            }}
            onCancel={() => setShowCamera(!showCamera)}
          />
        </View>
      ) : (
        <View style={{ display:'flex',width:'100%',alignItems:'center',justifyContent:'flex-start' }} >
          <View
            style={[
              sx({
                display: 'flex',
                justifyContent: 'space-around',
                flexDirection: 'row',
                alignItems: 'center',
                width: '100%',
                maxWidth: 350,
              }),
            ]}
          >
            <Pressable
              onPress={()=>{
                if(Platform.OS !=='web'){
                  pickImage()
                }else webPickImage()
              }}
              style={[
                sx({
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                  backgroundColor: '#fff',
                  width: [106, 120],
                  height: [106, 120],
                  borderRadius: 10,
                  shadowColor: '#000',
                  shadowOffset: { width: 1, height: 1 },
                  shadowOpacity: 0.2,
                  shadowRadius: 2,
                  elevation: 3,
                }),
                uploadIconStyles
              ]}
            >
              <Icon
                name="gallery"
                fill={['$primary']}
              />
                <I18Text  style={{
                  maxWidth: '80%',
                  textAlign: 'center',
                }}
                lineBreakMode='tail'
                numberOfLines={2}
                allowFontScaling={false}>
                  Upload from gallery
                </I18Text>  
            </Pressable>

            <Pressable
              onPress={handlePress}
              style={[
                sx({
                  display: showCameraIcon ? 'flex' : 'none',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                  backgroundColor: '#fff',
                  width: [106, 120],
                  height: [106, 120],
                  borderRadius: 10,
                  shadowColor: '#000',
                  shadowOffset: { width: 1, height: 1 },
                  shadowOpacity: 0.2,
                  shadowRadius: 2,
                  elevation: 3,
                  borderWidth:Platform.OS === 'web'?0: !status?.granted ? 1:0,
                  borderColor: Platform.OS === 'web'?'none': !status?.granted ? 'red':'none'  
                }),
                uploadIconStyles
              ]}
            >
              <Icon
                name="camera"
                fill={['$primary']}
              />
              <I18Text
                style={{
                  maxWidth: '80%',
                  textAlign: 'center',
                }}
                lineBreakMode='tail'
                numberOfLines={2}
                allowFontScaling={false}
              >
                Take Photo
              </I18Text>
            </Pressable>
          </View>
          <H5
            lineBreakMode='tail'
            numberOfLines={2}
            style={{
              maxWidth: 300,
              textAlign: 'center',
              marginBottom: 0,
              marginTop:10,
              color:Platform.OS === 'web'?'#000': !status?.granted ? 'red':'#000',
            }}
             adjustsFontSizeToFit={true}
         maxFontSizeMultiplier={1.5}
          >
            <I18Text onlyString  >
          {Platform.OS === 'web'? isShowHelperMessage ?helperMessage:'' : !status?.granted ? errorMessage:isShowHelperMessage? helperMessage:''}
            </I18Text>
          </H5>
        </View>
      )}
    </View>
     {imagePreview &&  <ScrollView horizontal={true} style={{ flexDirection: 'row' }}>
      {images.map((img, index) => {
        return (
          <View
            key={index}
            style={{
              padding: 10,
              borderWidth: 0.5,
              borderColor: '#1D76BB',
              margin: 5,
              borderRadius: 5,
              justifyContent: 'center',
              alignItems: 'center',
              marginTop:20
            }}
          >
            {renderThumbnail(img)}
            <Pressable
              style={{ position: 'absolute', right: -10, top: -10 }}
              onPress={() => {
                setImages([...images.filter((x, ind) => ind != index)]);
              }}
            >
              <Icon name="cancel" fill={['red']}  />
            </Pressable>
          </View>
        );
      })}
    </ScrollView> }
    </>
  )
}


const renderThumbnail = ({ url, type, name }) => {
  if (url && type) {
    if (type.startsWith('image/')) {
      return (
        <>
          <Image
            source={{ uri: url }}
            resizeMode={'cover'}
            alt='default-file'
            defaultSource={require('../../assets/default-file.jpg')}
            resizeMethod={'scale'}
            style={{ height: 80, width: 80, borderRadius: 5 }}
          />
          <P
            numberOfLines={1}
            ellipsizeMode={'tail'}
            style={{ maxWidth: 100, marginBottom: 3, marginTop: 3 }}
            adjustsFontSizeToFit={true}
            maxFontSizeMultiplier={1.5}
          >
            {name}
          </P>
        </>
      );
    } else if (type.startsWith('video/')) {
      return (
        <>
          <Image
            source={require('../../assets/video.jpg')}
            resizeMode={'cover'}
            alt='video'
            resizeMethod={'scale'}
            style={{ height: 80, width: 80, borderRadius: 5 }}
          />
          <P
            numberOfLines={1}
            ellipsizeMode={'tail'}
            style={{ maxWidth: 100, marginBottom: 3, marginTop: 3 }}
          >
            {name}
          </P>
        </>
      ); // Render video thumbnail here
    } else {
      return (
        <>
          <Image
            source={require('../../assets/default-file.jpg')}
            resizeMode={'cover'}
            resizeMethod={'scale'}
            alt='default-file'
            style={{ height: 80, width: 80, borderRadius: 5 }}
          />
          <P
            numberOfLines={1}
            ellipsizeMode={'tail'}
            style={{ maxWidth: 100, marginBottom: 3, marginTop: 3 }}
          >
            {name}
          </P>
        </>
      ); // Render generic document icon here
    }
  }

  return null;
};