import React, { createContext, useContext, useReducer, ReactNode } from 'react';

// Define proper types for feed details
export type MediaDeviceSelection = {
  deviceId: string;
  label: string;
  kind: MediaDeviceKind;
};

export type VoiceSelection = {
  value: string;
  name: string;
  gender: string;
  locale: string;
  label: string;
  default?:boolean
};

type InitialState = {
  feedDetails: any;
  devices: {
    audioinput: {
      selected?: MediaDeviceSelection;
      default?: MediaDeviceSelection;
      available: MediaDeviceSelection[];
    };
    audiooutput: {
      selected?: MediaDeviceSelection;
      default?: MediaDeviceSelection;
      available: MediaDeviceSelection[];
    };
    videoinput: {
      selected?: MediaDeviceSelection;
      default?: MediaDeviceSelection;
      available: MediaDeviceSelection[];
    };
  };
  voice: {
    selected?: VoiceSelection;
    default?: VoiceSelection;
    available: VoiceSelection[];
  };
  additionalSettings?: Record<string, any>;
};

const initialState: InitialState = {
  feedDetails: {},
  devices: {
    audioinput: { available: [] },
    audiooutput: { available: [] },
    videoinput: { available: [] },
  },
  voice: {
    available:[
      { value: "en-US-AriaNeural", name: "Aria", gender: "Female", locale: "en-US", label: "Aria (Female, en-US)" },
      { value: "en-US-AnaNeural", name: "Ana", gender: "Female", locale: "en-US", label: "Ana (Female, en-US)" },
      { value: "en-US-ChristopherNeural", name: "Christopher", gender: "Male", locale: "en-US", label: "Christopher (Male, en-US)" },
      { value: "en-US-EricNeural", name: "Eric", gender: "Male", locale: "en-US", label: "Eric (Male, en-US)" },
      { value: "en-US-GuyNeural", name: "Guy", gender: "Male", locale: "en-US", label: "Guy (Male, en-US)" },
      { value: "en-US-JennyNeural", name: "Jenny", gender: "Female", locale: "en-US", label: "Jenny (Female, en-US)" },
      { value: "en-US-MichelleNeural", name: "Michelle", gender: "Female", locale: "en-US", label: "Michelle (Female, en-US)" },
      { value: "en-US-RogerNeural", name: "Roger", gender: "Male", locale: "en-US", label: "Roger (Male, en-US)" },
      { value: "en-US-SteffanNeural", name: "Steffan", gender: "Male", locale: "en-US", label: "Steffan (Male, en-US)" },
      { value: "en-GB-LibbyNeural", name: "Libby", gender: "Female", locale: "en-GB", label: "Libby (Female, en-GB)" },
      { value: "en-GB-MaisieNeural", name: "Maisie", gender: "Female", locale: "en-GB", label: "Maisie (Female, en-GB)" },
      { value: "en-GB-RyanNeural", name: "Ryan", gender: "Male", locale: "en-GB", label: "Ryan (Male, en-GB)" },
      { value: "en-GB-SoniaNeural", name: "Sonia", gender: "Female", locale: "en-GB", label: "Sonia (Female, en-GB)" },
      { value: "en-GB-ThomasNeural", name: "Thomas", gender: "Male", locale: "en-GB", label: "Thomas (Male, en-GB)" },
      { value: "en-IN-NeerjaNeural", name: "Neerja", gender: "Female", locale: "en-IN", label: "Neerja (Female, en-IN)", default: true },
      { value: "en-IN-PrabhatNeural", name: "Prabhat", gender: "Male", locale: "en-IN", label: "Prabhat (Male, en-IN)" },
      { value: "en-CA-ClaraNeural", name: "Clara", gender: "Female", locale: "en-CA", label: "Clara (Female, en-CA)" },
      { value: "en-CA-LiamNeural", name: "Liam", gender: "Male", locale: "en-CA", label: "Liam (Male, en-CA)" },
      { value: "en-AU-NatashaNeural", name: "Natasha", gender: "Female", locale: "en-AU", label: "Natasha (Female, en-AU)" },
      { value: "en-AU-WilliamNeural", name: "William", gender: "Male", locale: "en-AU", label: "William (Male, en-AU)" }
    ],
    selected:{ value: "en-IN-NeerjaNeural", name: "Neerja", gender: "Female", locale: "en-IN", label: "Neerja (Female, en-IN)" },
  },
  additionalSettings: {},
};

type ActionType = {
  type: 'UPDATE_STATE';
  payload: Partial<InitialState>;
};

function feedDetailsReducer(state: InitialState, action: ActionType): InitialState {
  switch (action.type) {
    case 'UPDATE_STATE':
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

type AiAssistantContextType = {
  state: InitialState;
  updateState: (payload: Partial<InitialState>) => void;
};

const AiAssistantContext = createContext<AiAssistantContextType | undefined>(undefined);

export const AiAssistantAgentProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(feedDetailsReducer, initialState);

  const updateState = (payload: Partial<InitialState>) => {
    dispatch({ type: 'UPDATE_STATE', payload });
  };

  return (
    <AiAssistantContext.Provider value={{ 
      state, 
      updateState,
    }}>
      {children}
    </AiAssistantContext.Provider>
  );
};

export const useAiAssistantContext = () => {
  const context = useContext(AiAssistantContext);
  if (!context) {
    throw new Error('useAiAssistantContext must be used within an AiAssistantAgentProvider');
  }
  return context;
};