import 'raf/polyfill'
import 'setimmediate'

import { Provider } from 'app/provider'
import Head from 'next/head'
import React from 'react'
import type { SolitoAppProps } from 'solito'
import { useRouter } from 'next/router'
import { config } from 'app/redux/config'
import { Platform } from 'react-native'
import * as WebBrowser from 'expo-web-browser';

import '@livekit/components-styles';

if(Platform.OS === 'web') {
  WebBrowser.maybeCompleteAuthSession()
}

function MyApp({ Component, pageProps }: SolitoAppProps) {
  const router = useRouter()

  // Construct canonical URL with full path
  const canonicalUrl = `${config.baseUrl}${router.asPath === '/' ? '' : router.asPath}`

  return (
    <>
      <Head>
        {/* Primary Meta Tags */}
        <title>GENNOCTUA - Interior and Exterior Design Visualization</title>
        <meta
          name="description"
          content="Elevate your interior and exterior design game with our cutting-edge app. Whether you're looking to visualize a new piece of furniture in your living room or test out different tile patterns for your patio, our app has got you covered. Here's what you can do:"
        />
        
        {/* Keywords */}
        <meta
          name="keywords"
          content="interior design, exterior design, AR design, object placement, room visualization, home customization, augmented reality, design app, interior transformation, space planning"
        />
        
        {/* Canonical URL */}
        <link 
          rel="canonical" 
          href={canonicalUrl} 
        />

        {/* Favicon */}
        <link rel="icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" href="/favicon.ico" />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={canonicalUrl} />
        <meta 
          property="og:title" 
          content="GENNOCTUA - Interior and Exterior Design Visualization" 
        />
        <meta 
          property="og:description" 
          content="Transform your design vision with GENNOCTUA's advanced augmented reality tools." 
        />
        <meta property="og:image" content={`${config.baseUrl}/og-image.png`} />

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={canonicalUrl} />
        <meta 
          property="twitter:title" 
          content="GENNOCTUA - Interior and Exterior Design Visualization" 
        />
        <meta 
          property="twitter:description" 
          content="Transform your design vision with GENNOCTUA's advanced augmented reality tools." 
        />
        <meta 
          property="twitter:image" 
          content={`${config.baseUrl}/og-image.png`} 
        />

        {/* Geo Tags */}
        <meta name="geo.region" content="Global" />
        <meta name="robots" content="index, follow" />

        {/* Verification Tags (replace with your actual verification codes) */}
        {/* <meta name="google-site-verification" content="your-verification-code" /> */}
      </Head>
      <Provider>
        <Component {...pageProps} />
      </Provider>
    </>
  )
}

export default MyApp