import React, { createContext, useContext, useReducer, ReactNode } from 'react';

// Define types for image upload process
export interface ImageData {
  uri: string;
  type?: string;
  name?: string;
  file:any
}

export interface UploadFormData {
  image: string;
  tags: string[];
  caption:string;
  visibility:string;
  uploadId:string
}

interface UploadState {
  isModalVisible: boolean;
  currentStep: number;
  formData: UploadFormData;
  isLoading: boolean;
  error: string | null;
}

type UploadAction =
  | { type: 'OPEN_MODAL' }
  | { type: 'CLOSE_MODAL' }
  | { type: 'SET_STEP'; payload: number }
  | { type: 'SET_IMAGE'; payload: string }
  | { type: 'UPDATE_FORM_FIELD'; payload: { field: keyof UploadFormData; value: string } }
  | { type: 'RESET_FORM' }
  | { type: 'SET_LOADING'; payload: boolean }
  | { type: 'SET_ERROR'; payload: string | null };

interface UploadContextType {
  state: UploadState;
  openModal: () => void;
  closeModal: () => void;
  setStep: (step: number) => void;
  setImage: (image: string) => void;
  updateFormField: (field: keyof UploadFormData, value: string) => void;
  resetForm: () => void;
  setLoading: (loading: boolean) => void;
  setError: (error: string | null) => void;
}

// Initial state for the upload context
const initialState: UploadState = {
  isModalVisible: false,
  currentStep: 1,
  formData: {
    image: '',
    tags: [],
    visibility: 'public',
    caption:'',
    uploadId:''
  },
  isLoading: false,
  error: null,
};

// Reducer function to manage the upload state
const uploadReducer = (state: UploadState, action: UploadAction): UploadState => {
  switch (action.type) {
    case 'OPEN_MODAL':
      return { ...state, isModalVisible: true };
    case 'CLOSE_MODAL':
      return { ...state, isModalVisible: false };
    case 'SET_STEP':
      return { ...state, currentStep: action.payload };
    case 'SET_IMAGE':
      return {
        ...state,
        formData: { ...state.formData, image: action.payload },
      };
    case 'UPDATE_FORM_FIELD':
      return {
        ...state,
        formData: {
          ...state.formData,
          [action.payload.field]: action.payload.value,
        },
      };
    case 'RESET_FORM':
      return {
        ...state,
        currentStep: 1,
        formData: initialState.formData,
        error: null,
      };
    case 'SET_LOADING':
      return { ...state, isLoading: action.payload };
    case 'SET_ERROR':
      return { ...state, error: action.payload };
    default:
      return state;
  }
};

// Create context
const UploadContext = createContext<UploadContextType | undefined>(undefined);

// Provider component
export const UploadProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(uploadReducer, initialState);

  // Define actions
  const openModal = () => dispatch({ type: 'OPEN_MODAL' });
  const closeModal = () => dispatch({ type: 'CLOSE_MODAL' });
  const setStep = (step: number) => dispatch({ type: 'SET_STEP', payload: step });
  const setImage = (image: string) => dispatch({ type: 'SET_IMAGE', payload: image });
  const updateFormField = (field: keyof UploadFormData, value: string) =>
    dispatch({ type: 'UPDATE_FORM_FIELD', payload: { field, value } });
  const resetForm = () => dispatch({ type: 'RESET_FORM' });
  const setLoading = (loading: boolean) => dispatch({ type: 'SET_LOADING', payload: loading });
  const setError = (error: string | null) => dispatch({ type: 'SET_ERROR', payload: error });

  return (
    <UploadContext.Provider
      value={{
        state,
        openModal,
        closeModal,
        setStep,
        setImage,
        updateFormField,
        resetForm,
        setLoading,
        setError,
      }}
    >
      {children}
    </UploadContext.Provider>
  );
};

// Custom hook to use the upload context
export const useUpload = (): UploadContextType => {
  const context = useContext(UploadContext);
  if (context === undefined) {
    throw new Error('useUpload must be used within a UploadProvider');
  }
  return context;
};