import React from 'react';
import {
  Platform,
} from 'react-native';
import ProductDetailsStep from './product-details-step';
import UploadOptionsStep from './upload-options-step';
import { useUpload } from './upload-context';
import Dialog from 'app/components/dialog';
import { BottomModal } from 'app/components/bottom-modal';
import { useSx } from 'dripsy';


const ImageUploadModal: React.FC = () => {
  const { state } = useUpload();
  const { isModalVisible, currentStep } = state;

  // Function to render the current step content
  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return <UploadOptionsStep />;
      case 2:
        return <ProductDetailsStep />;
      default:
        return null;
    }
  };
  const sx=useSx()

  const handleCloseModal=()=>{
    // closeModal()
    // resetForm()
  }


  return (
  Platform.OS ==='web'?  <Dialog
    
    modalVisible={isModalVisible}
    onCloseModal={handleCloseModal}
    styles={sx({
      maxWidth:['100%',800],
      minWidth:['100%',800],
      maxHeight:'100%'
    })}
    >
        {renderStepContent()}
    </Dialog>: <BottomModal
  height={450}
    visible={isModalVisible}
    onDismiss={handleCloseModal}
    >
    {renderStepContent()}
    </BottomModal>
  );
};


export default ImageUploadModal;