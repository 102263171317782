import React from 'react'
import {
  View,
  Text,
  Image,
  ScrollView,
} from 'react-native'
import { useUpload } from './upload-context'
import { useSx } from 'dripsy'
import { Button } from 'app/components/button'
import { Icon } from 'app/components/icon/icon'
import { TextArea } from 'app/components/text-area'
import { DropDown } from 'app/components/dropdown'
import TagInput from './tag-Input'
import { makeUseAxiosHook } from 'app/redux/store'
import { useToast } from 'app/redux/hooks/use-toast'
import { transformServerResponseToUIState, usePostContext } from '../dashboard/post-context'

const ProductDetailsStep: React.FC = () => {
  const {
    state,
    updateFormField,
    closeModal,
    resetForm,
    setLoading,
  } = useUpload()
    const { posts, setPosts } = usePostContext();
  const { formData, isLoading } = state
    const message = useToast()
  const sx = useSx()

    const [, addFeed] = makeUseAxiosHook('default', {
      manual: true,
    })({
      url: `/api/v1/upload-feed`,
      method: 'POST',
    })


  const handleAddTag = (tags) => {
    updateFormField('tags', tags)
  }

  const handleSubmit = async () => {
      setLoading(true)
      const feedFormData = new FormData()
      feedFormData.append('image_url', formData.image)
      feedFormData.append('tags', JSON.stringify(formData?.tags))
      feedFormData.append('caption', formData?.caption)
      feedFormData.append('visibility', formData?.visibility)
      feedFormData.append('uploadId', formData?.uploadId)

      addFeed({
        data:feedFormData
      }).then((res)=>{
        console.log(res.data.project);
        setPosts({
          ...posts,
          data: [...transformServerResponseToUIState([res.data.project]),...posts.data],
          total: res.data.total
        })
        setLoading(false)
        resetForm()
        closeModal()
      }).catch((error)=>{
        setLoading(false)
        message({
          message: error.response?.data?.message,
          type: 'error',
        })
      })
    
  }

  const privacyOptions = [
    { label: 'Public', value: 'public', icon: 'globe', color: '#B6652F' },
    { label: 'Private', value: 'private', icon: 'lock', color: '#B6652F' },
  ]

  const handleCloseModal=()=>{
    closeModal()
    resetForm()
  }

  return (
    <View
      style={sx({
        flex: 1,
        padding: 16,
        width: '100%',
        justifyContent: 'space-betweenn',
      })}
    >
      <View
        style={{
       flex:1
        }}
      >
        <Text
          style={sx({
            fontSize: 16,
            fontWeight: 'bold',
            textAlign: 'center',
            paddingBottom: 10,
            borderBottomWidth: 1,
            width: '100%',
            marginBottom: 16,
          })}
        >
          Create New Post
        </Text>

        <View
          style={sx({
            flexDirection: ['column', 'row'],
            width: '100%',
            marginBottom: 20,
          })}
        >
          {formData.image && (
            <View
              style={sx({
                height: [300],
                backgroundColor: '#f0f0f0',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 8,
                overflow: 'hidden',
                width: ['100%'],
                flex: 1,
                display: ['none', 'flex'],
              })}
            >
              <Image
                source={{ uri: formData.image }}
                style={sx({ width: '100%', height: '100%' })}
                resizeMode="contain"
                alt='feed'
              />
            </View>
          )}

          <ScrollView
            style={sx({ flex: 1, padding: 16, width: '100%', minHeight: 300 })}
            contentContainerStyle={sx({
              alignItems: ['center', 'flex-start'],
              paddingBottom: 40,
            })}
          >
            <View
              style={sx({
                flex: 1,
                width: ['100%'],
                paddingHorizontal: [16, 0],
                marginLeft: [16, 0],
              })}
            >
              <TagInput tags={formData.tags} onTagsChange={handleAddTag} />
              <View
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: 'row',
                  alignItems: 'center',
                  paddingBottom: 8,
                  paddingTop: 8,
                }}
              >
                <View
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                  }}
                >
                  <Icon name="openEye" fill={['#262626']} />
                  <Text
                    style={sx({
                      fontSize: 14,
                      color: '#262626',
                      marginLeft: 8,
                    })}
                  >
                    Share with:
                  </Text>
                </View>
                <DropDown
                  data={privacyOptions}
                  selectedData={privacyOptions.find(
                    (option) => option.value === formData.visibility
                  )}
                  onSelectData={(value) =>
                    updateFormField('visibility', value?.value || value)
                  }
                  displayKey="label"
                  selectTextKey="label"
                  placeholder="Select Privacy"
                  mainContainerStyle={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    alignItems: 'center',
                    paddingBottom: 8,
                    paddingTop: 8,
                    maxWidth: 100,
                  }}
                  containerStyle={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    borderColor: 'transparent',
                    backgroundColor: '#fff',
                    justifyContent: 'flex-end',
                  }}
                  iconStyle={{ marginLeft: 8 }}
                  clearIcon={false}
                />
              </View>

              <TextArea
                inputStyle={{
                  width: '100%',
                }}
                styles={{
                  width: '100%',
                }}
                placeholder={'Add a Caption'}
                value={formData.caption}
                style={{ marginBottom: 10 }}
                onChangeText={(text) => updateFormField('caption', text)}
              />

              {/* {[
              'product_description',
              'company_name',
              'category',
              'product_name',
              'color',
            ].map((field: any) => (
              <TextField
                key={field}
                placeholder={field.replace('_', ' ')}
                value={formData[field]}
                style={{ marginBottom: 10 }}
                onChangeText={(text) => updateFormField(field, text)}
              />
            ))} */}
            </View>
          </ScrollView>
        </View>
      </View>
      <View
        style={sx({
          flexDirection: ['row'],
          justifyContent: 'flex-end',
          width: '100%',
        })}
      >
        <Button
          variantType="outlined"
          style={sx({
            borderRadius: 8,
            height: 44,
            minWidth: 150,
            marginRight: 15,
          })}
          onPress={handleCloseModal}
          disabled={isLoading}
        >
         Cancel
        </Button>
        <Button
          loading={isLoading}
          style={sx({
            borderRadius: 8,
            height: 44,
            minWidth: 150,
          })}
          onPress={handleSubmit}
        >
          Upload
        </Button>
      </View>
    </View>
  )
}

export default ProductDetailsStep
