import React, { createContext, useState, useContext, useCallback } from 'react';
import { Alert } from 'react-native';
import { useConfig } from './useConfig';
import { config } from 'app/redux/config';

export type ConnectionMode = 'cloud' | 'manual' | 'env';

// Toast notification for React Native
const useToast = () => {
  return useCallback((options: { type: 'success' | 'error'; message: string }) => {
    const { type, message } = options;
    
    // Simple Alert implementation - you might want to replace this with a custom toast component
    Alert.alert(
      type === 'success' ? 'Success' : 'Error',
      message,
      [{ text: 'OK' }]
    );
  }, []);
};

// Connection context type
type TokenGeneratorData = {
  shouldConnect: boolean;
  wsUrl: string;
  token: string;
  mode: ConnectionMode;
  disconnect: () => Promise<void>;
  connect: () => Promise<void>;
};

const ConnectionContext = createContext<TokenGeneratorData | undefined>(undefined);

// Main connection provider
export const ConnectionProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const sendMessage = useToast();
  const { config: appConfig } = useConfig();
  
  const [connectionDetails, setConnectionDetails] = useState<{
    wsUrl: string;
    token: string;
    mode: ConnectionMode;
    shouldConnect: boolean;
  }>({ 
    wsUrl: config.livekit.livekitUrl, 
    token: '', 
    shouldConnect: false, 
    mode: 'env' 
  });

  // Connect to the selected endpoint
  const connect = useCallback(
    async () => {
      try {
        let url = config.livekit.livekitUrl;
        
        // Check for custom URL in settings
        if (appConfig.settings.ws_url && appConfig.settings.ws_url.trim() !== '') {
          url = appConfig.settings.ws_url;
        }
        
        // Check if token is already provided in settings
        if (appConfig.settings.token && appConfig.settings.token.trim() !== '') {
          setConnectionDetails({
            wsUrl: url,
            token: appConfig.settings.token,
            shouldConnect: true,
            mode: 'manual'
          });
          return;
        }
        
        // Otherwise, fetch token from API
        try {
          const response = await fetch(`${config.baseUrl}/api/v1/livekit-token`);
          
          if (!response.ok) {
            throw new Error(`API error: ${response.status}`);
          }
          
          const data = await response.json();
          
          if (!data.accessToken) {
            throw new Error('No access token returned from API');
          }
          
          setConnectionDetails({
            wsUrl: url,
            token: data.accessToken,
            shouldConnect: true,
            mode: 'cloud'
          });
        } catch (error) {
          console.error('Token fetch error:', error);
          sendMessage({
            type: 'error',
            message: 'Failed to get token from API. Check your connection and try again.'
          });
        }
      } catch (error) {
        console.error('Connection error:', error);
        sendMessage({
          type: 'error',
          message: 'Failed to establish connection. Please try again later.'
        });
      }
    },
    [appConfig.settings.token, appConfig.settings.ws_url, sendMessage]
  );

  // Disconnect from the current connection
  const disconnect = useCallback(async () => {
    setConnectionDetails((prev) => ({ ...prev, shouldConnect: false }));
  }, []);

  return (
    <ConnectionContext.Provider
      value={{
        wsUrl: connectionDetails.wsUrl,
        token: connectionDetails.token,
        shouldConnect: connectionDetails.shouldConnect,
        mode: connectionDetails.mode,
        connect,
        disconnect,
      }}
    >
      {children}
    </ConnectionContext.Provider>
  );
};

// Hook to use connection within components
export const useConnection = () => {
  const context = useContext(ConnectionContext);
  if (context === undefined) {
    throw new Error('useConnection must be used within a ConnectionProvider');
  }
  return context;
};

// Optional wrapper for the provider (simplified for React Native)
export function CloudProvider({ children }: { children: React.ReactNode }) {
  return <>{children}</>;
}