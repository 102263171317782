import React from 'react'
import { Platform, Pressable } from 'react-native'
import { useSx, View } from 'dripsy'
import { TextField } from '../text-field'

interface TextAreaProps {
  styles?: any
  numberOfLines?: number
  showSearchIcon?: boolean
  showRightArrowIcon?: boolean
  showSendIcon?: boolean
  value: string
  onChangeText: (text: string) => void
  placeholder?: string
  maxLength?: number
  [key: string]: any // for additional props
  inputStyle?: any
}

export function TextArea({
  styles = {},
  numberOfLines = 4,
  value,
  onChangeText,
  placeholder = 'Enter text here...',
  maxLength,
  inputStyle,
  style,
  ...props
}: TextAreaProps) {
  const sx = useSx()

  return (
    <View
      style={[
        sx({
          backgroundColor: '$white',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }),
        styles,
      ]}
    >
      <TextField
        multiline
        numberOfLines={numberOfLines}
        textAlignVertical="top"
        style={[
          sx({
            padding: 2,
            flex: 1,
          }),
          sx({
            ...Platform.select({
              web: {
                outline: 'none',
              },
              native: {},
            }),
          }),
          style,
        ]}
        inputStyle={inputStyle}
        value={value}
        onChangeText={onChangeText}
        placeholder={placeholder}
        maxLength={maxLength}
        {...props}
      />
    </View>
  )
}
