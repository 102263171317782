import React, { useState, useEffect } from 'react';
import { View, Modal, StyleSheet, Animated, PanResponder, Dimensions, GestureResponderEvent, TouchableOpacity } from 'react-native';

interface BottomSheetProps {
  visible: boolean;
  children: React.ReactNode;
  onDismiss: () => void;
  height?:number
}

export const BottomModal: React.FC<BottomSheetProps> = ({ visible, children, onDismiss,height=300 }) => {
  const [panY] = useState<Animated.Value>(new Animated.Value(Dimensions.get('screen').height));
  const [resetPositionAnim] = useState<Animated.CompositeAnimation>(
    Animated.timing(panY, {
        toValue: 0,
        duration: 300,
        useNativeDriver: false
    })
  );
  const [closeAnim] = useState<Animated.CompositeAnimation>(
    Animated.timing(panY, {
        toValue: Dimensions.get('screen').height,
        duration: 500,
        useNativeDriver: false
    })
  );

  useEffect(() => {
    if (visible) {
      resetPositionAnim.start();
    }
  }, [visible, resetPositionAnim]);

  const panResponders = PanResponder.create({
    onStartShouldSetPanResponder: () => true,
    onMoveShouldSetPanResponder: () => false,
    onPanResponderMove: Animated.event<GestureResponderEvent>([null, { dy: panY }], { useNativeDriver: false }),
    onPanResponderRelease: (e, gs) => {
      if (gs.dy > 0 && gs.vy > 2) {
        return closeAnim.start(() => onDismiss());
      }
      return resetPositionAnim.start();
    },
  });

  const top = panY.interpolate({
    inputRange: [-1, 0, 1],
    outputRange: [0, 0, 1],
  });


  return (
    <Modal animationType="slide" visible={visible} transparent onRequestClose={()=>closeAnim.start(() => onDismiss())}>
      <TouchableOpacity onPress={()=>{closeAnim.start(() => onDismiss()) }} activeOpacity={1} style={styles.overlay}>
        <Animated.View {...panResponders.panHandlers} style={[styles.container, { top,height }]}>
          {children}
        </Animated.View>
      </TouchableOpacity>
    </Modal>
  );
};

const styles = StyleSheet.create({
  overlay: {
    flex: 1,
    justifyContent: 'flex-end',
  },
  container: {
    backgroundColor: '#ffffff',
    paddingTop: 12,
    borderTopRightRadius: 12,
    borderTopLeftRadius: 12,
    shadowColor: '#000000',
    shadowOffset: { width: 1, height: 1 },
    shadowOpacity: 0.5,
    shadowRadius: 5,
    elevation: 5,
  },
});


