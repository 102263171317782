import React, { createContext, useState, useContext, useEffect, ReactNode } from 'react';
import { Post } from './types';
import { formatDate } from 'app/util/helper';

interface PostContextType {
  posts: Posts;
  setPosts: (post: any) => void;
  toggleLike: (postId: string) => void;
  updateComment: (postId: string, obj: any) => void;
  updatePost: (postId: string, obj: any) => void;
  deletePost: (postId: string) => void;
}

interface Posts {
  data: Post[];
  loading: boolean;
  hasMore: boolean;
  refetch: boolean;
  loadMore: boolean;
  total: number;
}

const PostContext = createContext<PostContextType | undefined>(undefined);

export const usePostContext = () => {
  const context = useContext(PostContext);
  if (context === undefined) {
    throw new Error('usePostContext must be used within a PostProvider');
  }
  return context;
};

interface PostProviderProps {
  children: ReactNode;
}

export const PostProvider: React.FC<PostProviderProps> = ({ children }) => {
  const [posts, setPosts] = useState<Posts>({
    data: [],
    loading: true,
    hasMore: true,
    refetch: true,
    loadMore: false,
    total: 0,
  });

  const toggleLike = (postId: string) => {
    setPosts(prev => {
      return {
        ...prev,
        data: prev.data.map(post =>
          post._id === postId
            ? { ...post, isLiked: !post.isLiked, likeCount: post.isLiked ? post.likeCount - 1 : post.likeCount + 1 }
            : post
        )
      }
    }
    );
  };


  const updateComment = (postId: string, obj: any) => {
    setPosts(prev => {
      return {
        ...prev,
        data: prev.data.map(post =>
          post._id === postId
            ? { ...post, commentCount: obj.commentCount }
            : post
        )
      }
    }
    );
  };

  const updatePost = (postId: string, updatedData: any) => {
    setPosts(prev => {
      return {
        ...prev,
        data: prev.data.map(post =>
          post._id === postId
            ? { ...post, ...updatedData }
            : post
        )
      }
    });
  };

  const deletePost = (postId: string) => {
    setPosts(prev => {
      return {
        ...prev,
        data: prev.data.filter(post => post._id !== postId)
      }
    });
  };


  return (
    <PostContext.Provider
      value={{
        posts,
        setPosts,
        toggleLike,
        updateComment,
        updatePost,
        deletePost
      }}
    >
      {children}
    </PostContext.Provider>
  );
};

export default PostProvider;



export function transformServerResponseToUIState(serverResponse) {
  return serverResponse.map(item => {
    // Get the first page if available
    const page = item.pages && item.pages.length > 0 ? item.pages[0] : null;

    // Construct the image URL properly
    const imageUrl = page && page.sourceImage ? page.sourceImage : '';

    return {
      id: item._id || '',// to be remove
      _id: item._id || '',
      pageId: page?._id,
      userId: item.createdBy?._id || '',
      userName: item.createdBy?.name || '',
      userAvatar: item.createdBy?.avatar || '',
      // location: 'Bhubaneswar, Odisha', // Default location or empty string if you prefer
      images: [imageUrl], // Using the sourceImage from the first page
      likeCount: item.likeCount || 0,
      isLiked: item.isLiked, // Default value as not provided in server response
      caption: page?.caption || '',
      timestamp: formatDate(new Date(item.createdAt)),
      commentCount: item.commentCount || 0,
      createdAt: item.createdAt,
      type: item.projectType || '',
      sourceImage: page?.sourceImage,
      resultImage: page?.resultImage,
      pages: item.pages,
      tags: page?.tags || []
    };
  });
}

