// const url="http://192.168.31.189:3030"// my mobile net
// const url = 'http://192.168.31.189:3030' //                                                                                                  yspl
const url='https://gennoctua.yoctotta.com';
// const url = 'https://gennoctua.com'
const baseUrl= url
export const config = { 
   baseUrl:  process.env.NEXT_PUBLIC_BASE_URL || `${baseUrl}`,
   authTokenName:  process.env.NEXT_PUBLIC_AUTH_TK_NAME || 'gennoctuatkt',
   cookie:{
       domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN || 'localhost'
   },
   expoConfig:{
    projectId:"466ccb57-48f7-48fb-a6c8-a0e0d9b4c585"
   },
   expoClientId:'GOOGLE_GUID.apps.googleusercontent.com', 
   googleWebClientId:  process.env.NEXT_PUBLIC_GOOGLE_WEB_CID || '904065359696-92qf7stjdkso303j4fbcfnudkg19a19k.apps.googleusercontent.com',
   googleAndroidClientId:  process.env.NEXT_PUBLIC_GOOGLE_ANDROID_CID || '904065359696-b2bu42jje3t8bfo70l1d25n2t8q9728d.apps.googleusercontent.com',
   googleIOSClientId: process.env.NEXT_PUBLIC_GOOGLE_IOS_CID || '904065359696-kb5k6efj5l97oejugt579sntgrg4ncgc.apps.googleusercontent.com',
   appleAuthClient: 'com.zclap.gennoctua.auth',
   // Add the iOS URL scheme explicitly
  iosURLScheme: 'com.googleusercontent.apps.904065359696-kb5k6efj5l97oejugt579sntgrg4ncgc',
  // Add debug SHA-1 for development
  androidDebugSHA1: 'FB:49:FF:9B:93:FB:A1:8F:12:B8:BD:75:78:3A:45:71:18:92:20:59',
  androidPackageName: 'com.zclap.gennoctua',
  livekit: {
  // "apiKey": "devkey",
  // "apiSecret": "devsecretdevsecretdevsecretdevsecret",
  //   livekitUrl: "http://localhost:7880",

  "apiKey": "APIUn3gSMDZEDcJ",
  "apiSecret": "NnPNZvkxoNok5tFkq4UOLnzepEnMcnkt0NvVZ1XjWfC",
    livekitUrl: "https://rtc.gennoctua.com",
    
    mongoUri: "mongodb://localhost:27017/gennoctua_assistant",
    backendUrl: "https://assistant.gennoctua.com",
      appConfig: {
        title: "Gennoctua Live Assistant",
        description: "It provides realtime assistance based on your voice and video inputs.",
        video_fit: "cover",
        settings: {
          editable: true,
          theme_color: "cyan",
          chat: true,
          inputs: {
            camera: true,
            mic: true,
            sip: false,
          },
          outputs: {
            audio: true,
            video: true,
          },
          ws_url: "",
          token: "",
        },
        show_qr: false,
      },
},
 };
                                                                                            